import React, { useMemo, useCallback, useEffect, useState } from 'react'
import { Photo, getPhotos } from '../../app/requests/photos'

import './styles.css'
export const Carousel: React.FC = () => {
    const [photos, setPhotos] = useState<Photo[]>([])
    const [carouselInterval, setCarouselInterval] = useState<NodeJS.Timeout | null>(null)

    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0)
    const [isPaused, setIsPaused] = useState(false)

    const currentlySelectedPhoto = photos[currentPhotoIndex] ?? { title: 'No photo', url: '/assets/no-image.png' }

    useEffect(() => {
        const fetchPhotos = async () => {
            try {
                const response = await getPhotos()
                setPhotos(response.data)
            } catch (err) {
                console.error('Failed to get outpost builders photos', err)
            }
        }

        fetchPhotos()
    }, [])

    useEffect(() => {
        if (carouselInterval) {
            clearInterval(carouselInterval)
        }

        if (isPaused) {
            return
        }

        setCarouselInterval(setInterval(() => {
            setCurrentPhotoIndex((prevIndex) => {
                const nextIndex = prevIndex + 1
                return nextIndex >= photos.length ? 0 : nextIndex
            })
        }, 3000))
    }, [isPaused, photos])

    const onSelectPhoto = useCallback((index: number) => {
        setCurrentPhotoIndex(index)
        setIsPaused(true)
    }, [])

    const imageStyle = useMemo(() => {
        return {
            backgroundImage: `url(${photos[currentPhotoIndex]?.url})`
        }
    }, [photos, currentPhotoIndex])


    const goBack = useCallback(() => {
        setCurrentPhotoIndex((prevIndex) => {
            const nextIndex = prevIndex - 1
            return nextIndex < 0 ? photos.length - 1 : nextIndex
        })
        setIsPaused(true)
    }, [photos])

    const goNext = useCallback(() => {
        setCurrentPhotoIndex((prevIndex) => {
            const nextIndex = prevIndex + 1
            return nextIndex >= photos.length ? 0 : nextIndex
        })
        setIsPaused(true)
    }, [photos])

    const createThumbnailStyle = useCallback((url: string) => {
        return {
            backgroundImage: `url(${url})`
        }
    }, [])

    return (
        <>
            <h2>Why Outpost?</h2>
            <h3>One Word... Quality.</h3>
            <div
                className="image-container"
                aria-label={currentlySelectedPhoto.title}
                style={imageStyle}
            >
                <div className="title-container">
                    <button onClick={goBack}>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M39.5834 7.25005L39.5834 44.7501C39.5822 45.1297 39.4775 45.5019 39.2806 45.8265C39.0836 46.151 38.8018 46.4158 38.4656 46.5921C38.1294 46.7685 37.7514 46.8498 37.3724 46.8274C36.9934 46.8049 36.6277 46.6795 36.3147 46.4646L9.23135 27.7146C8.10843 26.9376 8.10843 25.0667 9.23135 24.2876L36.3147 5.53755C36.6271 5.32052 36.9929 5.19325 37.3726 5.16957C37.7522 5.14588 38.1311 5.22669 38.468 5.40321C38.805 5.57973 39.0871 5.84521 39.2837 6.17081C39.4804 6.49641 39.584 6.86968 39.5834 7.25005Z" fill="white" fillOpacity="0.3"/> </svg>
                    </button>
                    <h4>{currentlySelectedPhoto.title}</h4>
                    <button onClick={goNext}>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M10.4166 7.25005L10.4166 44.7501C10.4178 45.1297 10.5225 45.5019 10.7194 45.8265C10.9164 46.151 11.1982 46.4158 11.5344 46.5921C11.8706 46.7685 12.2486 46.8498 12.6276 46.8274C13.0066 46.8049 13.3723 46.6795 13.6853 46.4646L40.7687 27.7146C41.8916 26.9376 41.8916 25.0667 40.7687 24.2876L13.6853 5.53755C13.3729 5.32052 13.0071 5.19325 12.6274 5.16957C12.2478 5.14588 11.8689 5.22669 11.532 5.40321C11.195 5.57973 10.9129 5.84521 10.7163 6.17081C10.5196 6.49641 10.416 6.86968 10.4166 7.25005Z" fill="white" fillOpacity="0.3"/> </svg>
                    </button>
                </div>
            </div>
            <div className="image-nav">
                {
                    photos.length === 0 && <p>Sorry, no photos to show right now.</p>
                }
                {
                    photos.map((photo, index) => (
                        <button
                            key={photo.url}
                            className={index === currentPhotoIndex ? 'selected' : ''}
                            onClick={() => onSelectPhoto(index)}
                            style={createThumbnailStyle(photo.url)}
                        />
                    ))
                }
            </div>
        </>
    )
}

