import React from 'react'
import { createRoot } from "react-dom/client"

import { ContactForm } from './components/ContactForm'
import { Carousel } from './components/Carousel'
import { Testimonials } from './components/Testimonials'
import { setupNavBarFunctionality } from './navBarFunctionality'

const setupSection = (id: string, component: React.ReactNode) => {
    const node = document.getElementById(id)

    if (!node) {
        throw Error(`No #${id} in document.`)
    }

    const root = createRoot(node)
    root.render(component)
}

setupSection('carousel', <Carousel />)
setupSection('contact', <ContactForm />)
setupSection('testimonials', <Testimonials />)

setupNavBarFunctionality()
