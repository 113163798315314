const automaticNavBarScrollAction = () => {
    const nav = document.querySelector('nav')

    if (!nav) {
        throw new Error('Failed to find nav bar elements')
    }

    if (window.innerWidth < 768) {
        return

    }

    const scrollPointToCloseNav = 400

    if (window.scrollY > scrollPointToCloseNav) {
        nav.classList.remove('opened');
    } else {
        nav.classList.add('opened');
    }
}

export const setupNavBarFunctionality = () => {
    const openNavButton = document.querySelector('nav .open')
    const closeNavButton = document.querySelector('nav .close')
    const navLinkItems = document.querySelectorAll('nav .content > a')

    const nav = document.querySelector('nav')

    if (!nav || !openNavButton || !closeNavButton || !navLinkItems.length) {
        throw new Error('Failed to find nav bar elements')
    }

    if (window.innerWidth > 768) {
        nav.classList.add('opened');
    }

    for (let i = 0; i < navLinkItems.length; i++) {
        navLinkItems[i].addEventListener('click', function () {
            nav.classList.remove('opened')
            window.removeEventListener('scroll', automaticNavBarScrollAction)
        })
    }

    window.addEventListener('scroll', automaticNavBarScrollAction)

    openNavButton.addEventListener('click', () => {
        nav.classList.add('opened')
        window.removeEventListener('scroll', automaticNavBarScrollAction)
    })

    closeNavButton.addEventListener('click', () => {
        nav.classList.remove('opened')
        window.removeEventListener('scroll', automaticNavBarScrollAction)
    })
}
