import { AxiosResponse } from 'axios'
import { $axios } from './axios'

export const createMessage = (body: {
    name: string
    phone: string
    message: string
}): Promise<AxiosResponse<void>> => {
    return $axios.post('/messages', body)
}
