import React, { useState, useEffect, useRef } from 'react'
import {
    Testimonial,
    getTestimonials, 
} from '../../app/requests/testimonials'
import './styles.css'

type Props = {
}

export const Testimonials: React.FC<Props> = () => {
    const [testimonials, setTestimonials] = useState<Testimonial[]>([])
    const [scrollDirection, setScrollDirection] = useState<'down' | 'up'>('down')
    const [isHovering, setIsHovering] = useState(false)
    const [isInView, setIsInView] = useState(false);

    const scrollContainerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        getTestimonials().then((res) => {
            setTestimonials(res.data)
        })
    }, [])

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    setIsInView(entry.isIntersecting)
                })
            },
            { threshold: 0.1 } // Adjust this value as needed
        )

        if (scrollContainerRef.current) {
            observer.observe(scrollContainerRef.current)
        }

        return () => {
            observer.disconnect()
        }
    }, [])

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current
        if (!scrollContainer) return

            const scrollStep = 1 // Adjust this value for faster or slower scrolling
            const scrollInterval = 10 // Adjust this value for faster or slower scrolling

            const scroll = () => {
                if (isHovering || !isInView) {
                    return
                }

                if (scrollDirection === 'down') {
                    if (scrollContainer.scrollTop < scrollContainer.scrollHeight - scrollContainer.clientHeight - 2) {
                        scrollContainer.scrollTop += scrollStep
                    } else {
                        setScrollDirection('up')
                    }
                } else {
                    if (scrollContainer.scrollTop > 0) {
                        scrollContainer.scrollTop -= scrollStep
                    } else {
                        setScrollDirection('down')
                    }
                }
            }

            const intervalId = setInterval(scroll, scrollInterval)

            return () => clearInterval(intervalId)
    }, [scrollDirection, isHovering])

    return (
        <>
            <div className="col-title">
                <h2>Our Satisfied Customers</h2>
            </div>
            <div
                className="col-testimonials"
                ref={scrollContainerRef}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            >
                {testimonials.map((testimonial) => (
                    <div key={testimonial.quote} className="testimonial">
                        <p>{`"${testimonial.quote}"`}</p>
                        <h6>{testimonial.name}</h6>
                    </div>
                ))}
            </div>
        </>
    )
}

